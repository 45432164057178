import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    active: Boolean
  };

  connect() {
    window.albums ||= [];
    window.albums.push(this);
  }

  markAsActive() {
    this.activeValue = true;
  }

  markAsInactive() {
    this.activeValue = false;
  }

  activeValueChanged(active) {
    if (active) {
      this.element.animate([
        { transform: 'rotate(0deg)' },
        { transform: 'rotate(360deg)' },
      ], { duration: 500, iterations: Infinity });
    } else {
      // Stop the spinning by resetting the rotation back to right-side-up.
      // Pause it in its current position, then animate to 360deg (the original animation's end)
      const animations = this.element.getAnimations()
      animations.forEach((animation) => {
        animation.pause();
      });
      this.element.animate([
        { transform: 'rotate(360deg)' },
      ], { duration: 100 });
      animations.forEach((animation) => {
        animation.cancel();
      });
    }
  }
}
