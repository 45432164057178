import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    paused: Boolean,
  };

  connect() {
    window.trackPlayers ||= [];
    window.trackPlayers.push(this);
  }

  markAsPlaying() {
    this.pausedValue = false;
  }

  markAsPaused() {
    this.pausedValue = true;
  }

  // Just in case the track player is a `play_pause_button`
  pausedValueChanged(paused) {
    this.element.dataset.paused = paused;
  }
}
