import Hls from "hls.js";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["poster", "player"];
  static values = {
    poster: Boolean,
    paused: Boolean,
  };

  /*
  :::::::::::::::
  :: Lifecycle ::
  :::::::::::::::
  */

  initialize() {
    // if (Hls.isSupported()) {
    //   this.hls = new Hls();
    //   this.hls.attachMedia(this.playerTarget);
    // }
  }

  connect() {
    // if (Hls.isSupported()) {
    //   this.hls.loadSource(this.data.get("source"));
    // } else if (this.playerTarget.canPlayType("application/vnd.apple.mpegurl")) {
    //   this.playerTarget.src = this.data.get("source");
    // } else {
    //   this.playerTarget.src = this.data.get("fallbackSource");
    // }

    // TEMP: Always use fallback source because HLS has trouble looping and with `object-fit: cover`
    this.playerTarget.src = this.data.get("fallbackSource");

    // If prefers reduced motion, call pause and reenable the poster
    if (window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      this.pause();
      this.posterValue = true;
    }

    window.videoPlayers ||= [];
    window.videoPlayers.push(this);
  }

  /*
  ::::::::::::::::::::
  :: Event Handlers ::
  ::::::::::::::::::::
  */

  handlePlayerPlay() {
    this.pausedValue = false;
    this.posterValue = false;
  }

  handlePlayerPause() {
    this.pausedValue = true;
  }

  /*
  ::::::::::::::::::::::::::
  :: Custom Value Getters ::
  ::::::::::::::::::::::::::
  */

  /*
  ::::::::::::::::::::
  :: Public methods ::
  ::::::::::::::::::::
  */

  posterValueChanged(visible) {
    if (!this.hasPosterTarget) {
      return;
    }

    if (visible) {
      this.posterTarget.style.setProperty("display", "block");
    } else {
      this.posterTarget.style.setProperty("display", "none");
    }
  }
}
