import { Controller } from "@hotwired/stimulus";
import app from "../hov_app";

export default class extends Controller {
  connect() {
    // this.scrollDisabled = document.documentElement.style.getPropertyValue("overflow") === "hidden";
  }
  
  // Advancing in the turbo frame doesn't automatically scroll to the top
  // of the page, so we need to do that manually.
  scrollToTop() {
    // window.addEventListener("turbo:frame-load", () => {
    //   window.scrollTo({top: 0});
    // })
  }

  toggleDocumentScroll() {
    // this.scrollDisabled = document.documentElement.style.getPropertyValue("overflow") === "hidden";
    // this.scrollDisabled ?  this.enableDocumentScroll()  : this.disableDocumentScroll();
  }

  disableDocumentScroll() {
    // document.documentElement.style.setProperty("overflow", "hidden");
  }

  enableDocumentScroll() {
    // document.documentElement.style.removeProperty("overflow", "hidden");
  }

  // Anchor links don't work in turbo frames, 
  // so we need to scroll to an element position manually
  scrollToTarget() {
    // if (app.isMobile()) {
    //   return;
    // }
    
    // if (this.element.dataset.scrollTarget === "top") {
    //   window.scrollTo({top: 0, behavior:"smooth"});
    // } else {
    //   const scrollTarget = document.querySelector(`[data-js-selector=${this.element.dataset.scrollTarget}`);
    //   const offsetTop = scrollTarget.getBoundingClientRect().top + window.scrollY;
    //   window.scrollTo({top: offsetTop, behavior:"smooth"});
    // }
  }
};
