import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];
  static values = {
    active: String,
  };

  open = (button) => { 
    if (button.target.dataset.jsTargetSelector) {
      this.target = document.getElementById(button.target.dataset.jsTargetSelector);
      if (this.target) this.target.dataset.active = true;
    }
    this.activeValue = button.target.id;
    this.buttonToggle();
  }

  close = (button) => {
    if (button.target.dataset.jsTargetSelector) {
      this.target = document.getElementById(button.target.dataset.jsTargetSelector);
      if (this.target) this.target.dataset.active = false;
    }
    this.activeValue = button.target.id;
    this.buttonToggle();
  }

  buttonToggle = () => {
    this.buttonTargets.forEach((button) => {
      if (button.id === this.activeValue) {
        button.setAttribute("aria-pressed", true);
        button.setAttribute("aria-expanded", true);
      } else {
        button.setAttribute("aria-pressed", false);
        button.setAttribute("aria-expanded", false);
        button.focus();
      }
    })
  }
}
