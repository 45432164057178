import { Controller } from "@hotwired/stimulus";
import app from "../hov_app";

export default class extends Controller {
  static values = { theme: String }
  static targets = ["section", "mobileSection", "snapContainer"];

  connect() {
    if (!this.hasSnapContainerTarget) {
      return;
    }

    this.mobileNav = document.querySelector(`[data-nav-theme-target="mobileNav"]`);
    this.nav = document.querySelector(`[data-nav-theme-target="nav"]`);
    
    this.initScrollObserver();
    this.observeElements();

    addEventListener("resize", () => {
      this.observeElements();
    });
  }

  initScrollObserver() {
    let scrollPos = app.isMobile() ?  this.snapContainerTarget.scrollTop : window.scrollY;
    let lastScrollTop = scrollPos || document.documentElement.scrollTop;
    this.observer = new IntersectionObserver(([entry]) => {
      let scrollPos = app.isMobile() ?  this.snapContainerTarget.scrollTop : window.scrollY;
      const scrollTopPosition = scrollPos || document.documentElement.scrollTop;
      this.scrollingDown = scrollTopPosition > lastScrollTop;
      this.scrollingUp = scrollTopPosition < lastScrollTop;
      
      if (app.isMobile()) {
        this.handleMobileIntersection(entry)
      } else {
        this.handleDesktopIntersection(entry)
      }

      lastScrollTop = scrollTopPosition <= 0 ? 0 : scrollTopPosition;
    }, { threshold: [0, 0.25, 0.5, 0.75, 1] });
  }

  observeElements() {
    this.setInitialNavTheme();
    if (app.isMobile()) {
      this.mobileSectionTargets.forEach((section) => {
        this.observer.observe(section);
      });
    } else {
      this.sectionTargets.forEach((section) => {
        this.observer.observe(section);
      });
    }
  }

  // Mobile targets might be asynchronously loaded, so we need to observe when they are connected.
  mobileSectionTargetConnected(element) {
    if (!app.isMobile()) {
      return;
    }

    if (!this.observer) {
      this.initScrollObserver();
    }

    this.observer.observe(element);
  }

  // Targets might be asynchronously loaded, so we need to observe when they are connected.
  sectionTargetConnected(element) {
    if (app.isMobile()) {
      return;
    }

    if (!this.observer) {
      this.initScrollObserver();
    }

    this.observer.observe(element);
  }

  handleMobileIntersection(entry) {
    if (this.scrollingDown && entry.intersectionRatio > 0.75) {
      this.themeValue = entry.target.dataset.navTheme;
    } else if (this.scrollingUp && entry.intersectionRatio > 0.75) {
      this.themeValue = entry.target.dataset.navTheme;
    }
  }

  handleDesktopIntersection(entry) {
    if (this.scrollingDown && entry.intersectionRatio === 0) {
      this.themeValue = entry.target.nextElementSibling?.dataset?.navTheme;
    } else if (this.scrollingUp && entry.intersectionRatio < 0.2 && entry.intersectionRatio > 0) {
      this.themeValue = entry.target.dataset.navTheme;
    }
  }

  // Linking from a different page can sometimes result in the nav loading
  // with black text. Since we always open the new page at the top, we should
  // always set the nav theme to white-black.
  setInitialNavTheme() {
    this.themeValue = "white-black";
  }

  // NOTE: value might change before `connect` so these elements might not be available yet
  themeValueChanged(theme) {
    if (this.mobileNav) {
      this.mobileNav.dataset.theme = theme;
      this.mobileNav.dataset.initialTheme = theme;
    }

    if (this.nav) {
      this.nav.dataset.theme = theme;
    }
  }
}
