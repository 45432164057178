import { Controller } from "@hotwired/stimulus";
import focusLock from "dom-focus-lock";

export default class extends Controller {
  static targets = ["close", "closeLink"];
  static values = {};
  static outlets = [];

  connect() {
    this.focusReturn = document.activeElement;
    document.documentElement.style.setProperty("overflow", "hidden");
    this.closeTarget.focus();
    focusLock.on(this.element, {
      initialFocus: this.closeTarget,
    });

    this.element.animate(
      [{ transform: "translateX(100%)" }, { transform: "translateX(0)" }],
      {
        duration: 300,
        fill: "forwards",
      }
    );
  }

  disconnect() {
    document.documentElement.style.removeProperty("overflow");
    document.body.style.removeProperty("overflow");
  }

  close() {
    this.closeLinkTarget.click();
    this.element
      .animate([{ transform: "translateX(100%)" }], {
        duration: 300,
        fill: "forwards",
      })
      .finished.then(() => {
        document.documentElement.style.removeProperty("overflow");
      });
    focusLock.off(this.element);
    if (this.focusReturn) {
      this.focusReturn.focus();
    }
    this.dispatch("close");
  }

  setBackdropUrl(url) {
    this.closeLinkTarget.setAttribute("href", url);
  }
}
