import { Controller } from "@hotwired/stimulus";
import app from "../hov_app";

export default class extends Controller {
  static targets = ["button"];
  static values = {};
  static outlets = [];

  copy = async () => {
    const shareData = {
      title: document.title || "The Book of HOV",
      url: window.location.href || "https://hov-linkedbyair.herokuapp.com/",
    };

    if (app.isMobile() && navigator.canShare(shareData)) {
      navigator.share(shareData);
      return;
    }

    const copyText = window.location.href;
    try {
      await navigator.clipboard.writeText(copyText);
      this.buttonTarget.classList.add("copied");
      setTimeout(() => {
        this.buttonTarget.classList.remove("copied");
      }, 2000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
}
