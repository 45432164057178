import { Controller } from "@hotwired/stimulus";
import { getFirstKeyboardFocusableElement } from "../utilities";

export default class extends Controller {
  static values = {
    active: Boolean,
  };

  connect() {
    window.toggleButtons ||= [];
    window.toggleButtons.push(this);

    this.target = document.getElementById(this.data.get("target"));
  }

  toggle() {
    this.activeValue = !this.activeValue;
  }

  activeValueChanged(active) {
    if (!this.target) {
      return;
    }

    this.element.setAttribute("aria-pressed", active);
    this.element.setAttribute("aria-expanded", active);
    this.target.dataset.active = active;
    // this.target.setAttribute("aria-hidden", !active);

    if (active) {
      const firstFocusable = getFirstKeyboardFocusableElement(this.target);
      if (firstFocusable) {
        firstFocusable.focus();
      }
      if (this.target.dataset.activeTheme) {
        this.target.dataset.theme = this.target.dataset.activeTheme;
      }
    } else if (this.target.dataset.initialTheme) {
      this.target.dataset.theme = this.target.dataset.initialTheme;
    }
  }
}
