/*
  This controller allows you to load content asynchronously
  dependant on the viewport size. Generally speaking, this should
  only be used in scenarios where the content's first paint is not
  important or can be hidden, as there will be a flash in between
  the moment the page loads and when the async content loads.

  Use this controller with a turbo frame that has data attributes
  declaring routes for different breakpoints. These attributes should
  be named like `data-responsive-content-[breakpoint]-url` where
  `[breakpoint]` is the name a breakpoint defined in Tailwind (e.g. `md`).
  `default` is the name of the smallest breakpoint, starting at 0px wide.

  Be sure that the Rails controller serves a page with that same
  turbo frame tag. For best results, you should have that controller
  action skip rendering the layout and all unessential computations.
  You may also want to disallow users to access that endpoint directly.

  Example usage:
    <%= turbo_frame_tag "home-page-content", data: {
      # Only use the `responsive-content` controller with a turbo frame tag
      controller: "responsive-content",

      # This is the "mobile" — i.e. what gets loaded when the viewport is at least 1px wide
      responsive_content_default_url: home_page_mobile_content_path,

      # This would be loaded if the browser is at least as wide as the medium breakpoint
      responsive_content_md_url: home_page_desktop_content_path,

      # This makes sure that resize events trigger new content 
      action: "resize@window->responsive-content#update"
    } %>
*/

import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  // NOTE: must be kept in sync with the breakpoints defined in Tailwind config
  static breakpoints = [
    { name: "3xl", minWidth: 1900 },
    { name: "2xl", minWidth: 1536 },
    { name: "xl", minWidth: 1280 },
    { name: "lg", minWidth: 1024 },
    { name: "ml", minWidth: 900 },
    { name: "md", minWidth: 768 },
    { name: "sm", minWidth: 640 },
    { name: "xs", minWidth: 415 },
    { name: "default", minWidth: 0 },
  ];
  static values = {
    url: String
  }

  connect() {
    this.update();
  }

  update() {
    this.urlValue = this.url;
  }

  urlValueChanged(url) {
    if (!url) {
      return
    }

    Turbo.visit(url, { action: "replace", frame: this.element.id });
  }

  get url() {
    const currentBreakpointAndSmaller = this.constructor.breakpoints.slice(
      this.constructor.breakpoints.indexOf(this.breakpoint)
    );

    for (let i = 0; i < currentBreakpointAndSmaller.length; i++) {
      const { name } = currentBreakpointAndSmaller[i];
      if (this.data.get(`${name}Url`)) {
        return this.data.get(`${name}Url`);
      }
    }

    return null;
  }

  get breakpoint() {
    return this.constructor.breakpoints.find((bp) => {
      return window.matchMedia(`(min-width: ${bp.minWidth}px)`).matches;
    });
  }
}
