import { Controller } from "@hotwired/stimulus";
import Player from "@vimeo/player";
import { timestampToFormattedTime } from "../utilities";

export default class extends Controller {
  static values = {
    captions: Boolean,
    currentTime: String,
    duration: String,
    progress: Number,
    paused: Boolean,
    poster: Boolean,
    textTracks: Array,
    textTrack: String,
  };
  static targets = [
    "closedCaptioningButton",
    "currentTime",
    "duration",
    "playPauseButton",
    "poster",
    "progressBar",
  ];

  /*
  :::::::::::::::
  :: Lifecycle ::
  :::::::::::::::
  */

  connect() {
    const iframe = this.element.querySelector("iframe");
    if (!iframe) {
      return;
    }

    iframe.setAttribute("action", "click->vimeo-player#togglePlayPause");
    iframe.addEventListener("click", () => {
      this.togglePlayPause();
    });
    this.player = new Player(iframe);

    const controller = this;
    this.player.on("play", () => {
      controller.pausedValue = false;
    });
    this.player.on("pause", () => {
      controller.pausedValue = true;
    });
    this.player.on("timeupdate", ({ seconds, percent, duration }) => {
      controller.currentTimeValue = timestampToFormattedTime(seconds);
      controller.durationValue = timestampToFormattedTime(duration);
      controller.progressValue = percent;
    });
    this.player.on("ended", () => {
      this.dispatch("ended");
    });

    this.durationValue = timestampToFormattedTime(
      Number(this.data.get("duration"))
    );

    const { language } = this.data.get('language');
    let languageLabel;
    switch (language?.toLowerCase()) {
      case 'es':
        languageLabel = "spanish";
        break;
      default:
        languageLabel = "english";
    }
    this.player.getTextTracks().then((tracks) => {
      this.textTracksValue = tracks;
      const textTrack = this.textTracksValue.find((track) => {
        return track.label.toLowerCase().includes(languageLabel);
      });

      if (textTrack) {
        this.textTrackValue = textTrack.language;
      } else if (Boolean(this.textTracksValue.length)) {
        this.textTrackValue = this.textTracksValue[0].language;
      } else {
        this.textTrackValue = false;
      }

      const isEnabled = textTrack && textTrack.mode === "showing";
      this.captionsValue = isEnabled;
    });

    window.vimeoPlayer = this;
  }

  /*
  ::::::::::::::::::::
  :: Public methods ::
  ::::::::::::::::::::
  */

  pause() {
    this.player.pause();
    this.dispatch("pause");
  }

  play() {
    this.posterValue = false;
    this.player.setMuted(false);
    this.player.play();
    this.dispatch("play");
  }

  scrub(event) {
    const { left, width } =
      this.progressBarTarget.parentNode.getBoundingClientRect();
    const percent = (event.clientX - left) / width;

    try {
      this.player.getDuration().then((duration) => {
        const timestamp = duration * percent;
        this.player.setCurrentTime(timestamp);
        this.play();
      });
    } catch (error) {
      console.error(error);
    }
  }

  toggleClosedCaptioning(e) {
    if (!Boolean(this.textTracksValue.length)) {
      return;
    }


    this.captionsValue = !this.captionsValue;
  }

  togglePlayPause() {
    if (this.pausedValue) {
      this.play();
    } else {
      this.pause();
    }
  }

  /*
  ::::::::::::::::::::::::::::
  :: Changed Value Handlers ::
  ::::::::::::::::::::::::::::
  */

  captionsValueChanged(captions) {
    if (!this.player) {
      return;
    }

    if (captions) {
      this.player.enableTextTrack(this.textTrackValue);
      this.closedCaptioningButtonTarget.setAttribute('data-active', true);
    } else {
      this.player.disableTextTrack();
      this.closedCaptioningButtonTarget.setAttribute('data-active', false);
    }
  }

  currentTimeValueChanged(currentTime) {
    this.currentTimeTarget.textContent = currentTime;
  }

  durationValueChanged(duration) {
    this.durationTarget.textContent = duration;
  }

  textTrackValueChanged(textTrack) {
    this.closedCaptioningButtonTarget.style.setProperty(
      "display",
      textTrack ? "block" : "none"
    );
  }

  pausedValueChanged(paused) {
    if (this.hasPosterTarget && !paused) {
      this.posterTarget
        .animate([{ opacity: 0 }], {
          duration: 200,
          easing: "ease-in-out",
          fill: "forwards",
        })
        .finished.then(() => {
          this.posterTarget.style.display = "none";
        });
    }

    if (this.hasPlayPauseButtonTarget) {
      this.playPauseButtonTarget.dataset.paused = paused;
    }
  }

  posterValueChanged(visible) {
    if (!this.hasPosterTarget) {
      return;
    }

    this.posterTarget.dataset.visible = visible;
  }

  progressValueChanged(progress) {
    this.progressBarTarget.style.setProperty(
      "transform",
      `scaleX(${progress})`
    );
  }
}
