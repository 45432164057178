import { Controller } from "@hotwired/stimulus";
import { getFirstKeyboardFocusableElement } from "../utilities";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["section"];
  static values = {};
  static outlets = [];

  connect() {
    this.state = {
      activeSection: null,
    }

    this.sectionTargets.forEach((section) => {
      section.addEventListener("mouseover", () => {
        this.state.activeSection = section;
        this.sectionToggle();
      });
      section.addEventListener("mouseout", () => {
        this.state.activeSection = null;
        this.sectionToggle();
      });
      section.addEventListener("click", () => {
        this.forwardToSection(section);
      });
    });

  }

  sectionToggle = () => {
    this.sectionTargets.forEach((section) => {
      this.foreground = section.querySelector(`[data-js-selector="foreground"]`);
      this.background = section.querySelector(`[data-js-selector="background"]`);
      
      if (section === this.state.activeSection) {
        this.foreground.classList.add("foreground", "cursor-pointer");
        this.background.classList.add("background", "cursor-pointer");
      } else {
        section.classList.remove(...section.classList);
        this.foreground.classList.remove(...this.foreground.classList);
        this.background.classList.remove(...this.background.classList);
      }
    })
  }

  forwardToSection = (section) => {
    this.sectionUrl = section.dataset.sectionUrl;
    Turbo.visit(this.sectionUrl);
  }
}
