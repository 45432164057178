export const invertTheme = (theme) => {
  const themeArr = theme.split("-");
  return themeArr[1] + "-" + themeArr[0];
};

export const getKeyboardFocusableElements = (element) => {
  if (!element) {
    return [];
  }

  return [
    ...element.querySelectorAll(
      'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
    ),
  ].filter(
    (el) => !el.hasAttribute("disabled") && !el.getAttribute("aria-hidden")
  );
};

export const getFirstKeyboardFocusableElement = (element) => {
  const focusableElements = getKeyboardFocusableElements(element);
  return focusableElements[0];
};

// Copied from https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript#24103596
export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = "_bookofhov_" + name + "=" + (value || "")  + expires + "; path=/";
}

export const getCookie = (name) => {
  let nameEQ = "_bookofhov_" + name + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

export const timestampToFormattedTime = (timestamp) => {
  const minutes = Math.floor(timestamp / 60);
  let seconds = Math.floor(timestamp % 60);
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
};