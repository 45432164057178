import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inlineArtifact"];
  static values = {};
  static outlets = [];

  connect() {
    this.figure = this.inlineArtifactTarget;
    this.artifactId = this.figure.dataset.jsArtifactId;
    this.target = document.querySelector(`[data-js-selector="inline-artifact-anchor-${this.artifactId}"]`);
    
    setTimeout(() => {
      this.setFigurePosition();
    }, "200");
    addEventListener("resize", () => {
      this.setFigurePosition();
    });

    this.target.addEventListener("mouseenter", () => {
      this.figure.dataset.active = true;
      this.applyMask(true);
    })

    this.target.addEventListener("mouseout", () => {
      this.figure.dataset.active = false;
      this.applyMask(false);
    })
  }

  applyMask = (maskState) => {
    if (window.innerWidth < 1024) return;
    this.figure.dataset.mask = maskState;
  }

  setFigurePosition = () => {
    const negativeMargin = this.figure.offsetHeight / 2;
    const offsetTop = `${this.target.offsetTop - negativeMargin}px`;
    if (this.target.offsetTop === 0) {
      return;
    }
    // Note, tailwind is not recognizing the top position, so we have to set it manually
    this.figure.dataset.visible = true;
    this.figure.style.top = `${offsetTop}`;
  }
}
