import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const iframe = this.element.querySelector("iframe");

    // NOTE: this is a hack to get around the fact that the iframe will
    // absorb all clicks, so we can't just add a click handler.
    // Source: https://stackoverflow.com/questions/2381336/detect-click-into-iframe-using-javascript
    window.addEventListener("blur", () => {
      setTimeout(() => {
        if (document.activeElement === iframe) {
          this.dispatch("click");
        }
      });
    });
  }
};
