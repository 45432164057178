import Hls from "hls.js";
import { Controller } from "@hotwired/stimulus";
import { timestampToFormattedTime } from "../utilities";

export default class extends Controller {
  static targets = [
    "audio",
    "currentTime",
    "duration",
    "playPauseButton",
    "progress",
    "video",
  ];
  static values = {
    // States that should only be updated by the audio player itself
    loading: Boolean,
    currentTime: Number,
    duration: Number,
    paused: Boolean,
  };

  /*
  :::::::::::::::
  :: Lifecycle ::
  :::::::::::::::
  */

  initialize() {
    // if (Hls.isSupported()) {
    //   this.hls = new Hls();
    //   this.player = this.videoTarget;
    // } else if (this.videoTarget.canPlayType("application/vnd.apple.mpegurl")) {
    //   this.player = this.videoTarget;
    // } else {
    //   this.player = this.audioTarget;
    // }
    this.player = this.audioTarget;

    window.inlineAudioPlayers ||= [];
    window.inlineAudioPlayers.push(this);
  }

  connect() {
    // if (this.hls && Hls.isSupported()) {
    //   this.hls.attachMedia(this.player);
    //   this.hls.loadSource(this.source);
    // } else if (this.player.canPlayType("application/vnd.apple.mpegurl")) {
    //   this.player.setAttribute("src", this.source);
    // } else {
    //   this.player.setAttribute("src", this.fallbackSource);
    // }
    this.player.setAttribute("src", this.fallbackSource);
  }

  /*
  ::::::::::::::::::::
  :: Custom getters ::
  ::::::::::::::::::::
  */

  get source() {
    return this.data.get('source');
  }

  get fallbackSource() {
    return this.data.get('fallbackSource');
  }

  /*
  ::::::::::::::::::::
  :: Event Handlers ::
  ::::::::::::::::::::
  */

  handlePlayerPlay() {
    this.pausedValue = false;
  }

  handlePlayerPause() {
    this.pausedValue = true;
  }

  /*
  ::::::::::::::::::::
  :: Public methods ::
  ::::::::::::::::::::
  */

  pause() {
    this.dispatch("before-pause");
    this.player.pause();
    this.dispatch("pause");
  }

  play() {
    // If the connection is fast, the loading icon would flash for a moment.
    // To prevent this, we delay it with a cancelable delay
    const delayBeforeShowingLoadingIcon = setTimeout(() => {
      this.loadingValue = true;
    }, 500);
    this.posterValue = false;

    this.dispatch("before-play");
    return this.player.play().then(() => {
      clearTimeout(delayBeforeShowingLoadingIcon);
      this.loadingValue = false;
      this.dispatch("play", { detail: { track: this.trackValue } });
    });
  }

  // When the current track changes, wait for the track to load, then update the audio player's duration label
  populateMetadata(e) {
    const { duration } = e.target;
    this.durationValue = duration;
  }

  scrub(event) {
    if (!this.hasProgressTarget || !this.durationValue) {
      return;
    }

    const { left, width } =
      this.progressTarget.parentNode.getBoundingClientRect();
    const percent = (event.clientX - left) / width;

    try {
      const timestamp = this.durationValue * percent;
      this.player.currentTime = timestamp;
      this.play();
    } catch (error) {
      console.error(error);
    }
  }

  togglePlayPause() {
    if (this.pausedValue) {
      return this.play();
    } else {
      return this.pause();
    }
  }

  updateCurrentTime() {
    const { currentTime } = this.player;
    this.currentTimeValue = currentTime;
  }

  /*
  ::::::::::::::::::::::::::::
  :: Changed Value Handlers ::
  ::::::::::::::::::::::::::::
  */

  currentTimeValueChanged(currentTime) {
    let formattedTimestamp;
    let progress = 0;
    if (typeof this.currentTimeValue !== "number") {
      formattedTimestamp = "--:--";
    } else {
      formattedTimestamp = timestampToFormattedTime(currentTime);
      progress =
        typeof this.durationValue === "number"
          ? currentTime / this.durationValue
          : 0;
    }

    this.currentTimeTargets.forEach((target) => {
      target.textContent = formattedTimestamp;
    });

    this.progressTargets.forEach((target) => {
      target.style.transform = `scaleX(${progress})`;
    });
  }

  durationValueChanged(duration) {
    const formattedTimestamp =
      typeof this.durationValue !== "number"
        ? "--:--"
        : timestampToFormattedTime(duration);
    this.durationTargets.forEach((target) => {
      target.textContent = formattedTimestamp;
    });
  }

  pausedValueChanged(paused) {
    this.playPauseButtonTargets.forEach((target) => {
      target.setAttribute("aria-pressed", !paused);
      target.setAttribute("data-paused", paused);
    });
  }
}
